(function (wpm, $, undefined) {

	wpm.jQueryExists = async () => new Promise(resolve => {

		(function waitForjQuery() {
			if (typeof jQuery !== "undefined") return resolve()
			setTimeout(waitForjQuery, 100)
		})()
	})

	wpm.wpmDataLayerExists = async () => new Promise(resolve => {
		(function waitForVar() {
			if (typeof wpmDataLayer !== "undefined") return resolve()
			setTimeout(waitForVar, 50)
		})()
	})


}(window.wpm = window.wpm || {}, jQuery))
